// utils/decryptionService.ts
import * as CryptoJS from 'crypto-js';
export class DecryptionService {
	private readonly secretKey;
	constructor() {
		const secretKey = process.env.REACT_APP_JWT_SECRET;
		if (!secretKey) {
			throw new Error('ENCRYPTION_SECRET_KEY is not set in environment variables');
		}
		this.secretKey = CryptoJS.enc.Hex.parse(CryptoJS.SHA256(secretKey).toString());
	}
	decryptDataFully(encryptedData: string): any {
		// First decryption
		const firstDecrypted = this.decryptData(encryptedData);
		// Check if the result is still encrypted
		if (typeof firstDecrypted === 'object' && firstDecrypted.data && typeof firstDecrypted.data === 'string') {
			// Second decryption
			const finalDecrypted = this.decryptData(firstDecrypted.data);
			return finalDecrypted;
		}
		// If it was only encrypted once, return the first decryption result
		return firstDecrypted;
	}

	// Existing decryptData method
	decryptData(encryptedData: string): any {
		const [iv, encrypted] = encryptedData.split(':');
		const decrypted = CryptoJS.AES.decrypt(encrypted, this.secretKey, {
			iv: CryptoJS.enc.Hex.parse(iv),
		});
		return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
	}
}

export const decryptionService = new DecryptionService();
