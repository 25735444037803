import { useEffect, useState } from 'react';
import mainService from '../../api/MainService';
import { useStore } from '../../store';
import './component.style.scss';
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from 'react-icons/md';
import { createDossierResult } from '../../ts/interfaces';
import { getFormDataInLocalStorage } from '../../utils/localStorage';

export default function NavArrows() {
	const { professionPhraseFormValues } = useStore().professionPhraseFormContainerContext;
	const { owners } = useStore().ownersFormContainerContext;
	const { setForms, dossierCreated, dossierCreatedSuccessfully, dossierDidNotCreatedSuccessfully, setLoading, setNumero, setPdf, isLoading } = useStore().formsContainerContext;
	const { updateStep, goToToolTipPage, currentStep } = useStore().activeStepContext;
	const { isRequestCabahutPrivileged, mifratLogic, checkTazhirNegishut, chosenProfessions } = useStore().chosenProfessionsContext;
	const { setValidityChanged, validityChanged } = useStore().formsContainerContext;
	const { thereIsNegishut } = checkTazhirNegishut();

	async function sendForm() {
		const form = document.querySelector('form');
		if (form) {
			const formElements = form.elements;
			let firstInvalidElement: any;

			Array.from(formElements).forEach((element) => {
				if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement || element instanceof HTMLSelectElement) {
					if (!element.checkValidity()) {
						if (!firstInvalidElement) {
							firstInvalidElement = element;
						}
					}
				}
			});

			if (firstInvalidElement) {
				firstInvalidElement.focus();
				const labelElement: any = document.querySelector(`label[aria-label="העלאת ${firstInvalidElement.id}"]`);
				if (labelElement) {
					labelElement.focus();
				}
				setValidityChanged(validityChanged + 1);
				return;
			}
		}

		try {
			setLoading(true);
			let is_getting_cabahut_priv = true;
			const formSummaryFromLocalStorage = localStorage.getItem('forms');
			const formTemplateAnswersFromLocalStorage = localStorage.getItem('formTemplateAnswers');
			const forms = formSummaryFromLocalStorage ? JSON.parse(formSummaryFromLocalStorage) : {};
			const formTemplateAnswers = formTemplateAnswersFromLocalStorage ? JSON.parse(formTemplateAnswersFromLocalStorage) : {};
			let all_phrases_are_true = false;

			if (forms.formSummary.chosenPhrases)
				all_phrases_are_true = Object.keys(forms.formSummary.chosenPhrases)
					.filter((phrase: any) => phrase.licensing_factor_code === 6)
					.every((_, value) => value === 1);

			const placed_is_ok = forms.formSummary.where_is_placed === '4';
			const multy_building_flag = forms.formSummary.multy_building_flag === '1'; // מבנה רב שימוש
			const is_entrance = forms.formSummary.idsend === 1; // בודק מיקום העסק במבנה

			if (multy_building_flag && (!is_entrance || forms.chosenProfessions?.some((prof: { profession: string }) => prof.profession === '6.4' || forms.businessDetails?.surface > 300))) {
				is_getting_cabahut_priv = false;
			}

			if (!all_phrases_are_true || !placed_is_ok) {
				is_getting_cabahut_priv = false;
			}
			const chosenPhrasesArray = transformChosenPhrases(professionPhraseFormValues?.chosenPhrases || {});
			setForms((prev: any) => ({
				...prev,
				platfromFlag: 'openRequest',
				formSummary: {
					chosenPhrases: chosenPhrasesArray,
					cabahut_maslul: String(professionPhraseFormValues?.cabahut_maslul && is_getting_cabahut_priv ? '1' : '0'),
					cabahut_ptor: String(professionPhraseFormValues?.cabahut_ptor && is_getting_cabahut_priv ? '1' : '0'),
				},
				businessDetails: {
					...forms.businessDetails,
					idstreet: parseInt(forms.formSummary.where_is_placed),
					ceilling_height: forms.businessDetails.ceilling_height ? Number(forms.businessDetails.ceilling_height) : null,
					nagish: thereIsNegishut ? 1 : 0,
				},
				formTemplateAnswers,
			}));
			forms.businessDetails = { ...forms.businessDetails, idstreet: parseInt(forms.formSummary.where_is_placed) };
			forms.formTemplateAnswers = formTemplateAnswers;
			const modifiedForms = modifyForms(forms);

			const res: createDossierResult = await mainService.createDossier(modifiedForms);

			if (res.dossierId) {
				setNumero(res.numero);
				setPdf(res.formSummaryPdf as string);
				dossierCreatedSuccessfully();
				localStorage.clear();
			} else {
				dossierDidNotCreatedSuccessfully();
			}
		} catch (error) {
			console.error(error);
			dossierDidNotCreatedSuccessfully();
		} finally {
			setLoading(false);
		}
	}

	function removeEmptyKeys(obj: any) {
		const newObj = { ...obj };
		for (const key in newObj) {
			if (newObj[key] === '' || newObj[key] === null) {
				delete newObj[key];
			} else if (typeof newObj[key] === 'object' && newObj[key] !== null) {
				newObj[key] = removeEmptyKeys(newObj[key]);
			}
		}
		return newObj;
	}
	const transformChosenPhrases = (chosenPhrasesObj: any) => {
		return Object.values(chosenPhrasesObj);
	};

	function modifyForms(forms: any) {
		const modifiedForms = { ...forms };
		for (const key in modifiedForms) {
			if (key === 'businessDetails') {
				modifiedForms[key] = substringAddresses(modifiedForms[key]);
				modifiedForms[key] = removeEmptyKeys(modifiedForms[key]);
			} else if (key === 'owners') {
				modifiedForms[key] = modifiedForms[key].map((owner: any) => {
					const modifiedOwner = substringAddresses(owner);
					return removeEmptyKeys(modifiedOwner);
				});
			}
		}
		return modifiedForms;
	}

	function substringAddresses(data: any) {
		if (data.address1) {
			data.address1 = data.address1.substring(0, 24);
		}
		if (data.adress1) {
			data.adress1 = data.adress1.substring(0, 24);
		}
		return data;
	}

	useEffect(() => {
		goToToolTipPage(dossierCreated);
	}, [dossierCreated]);

	const handleStepClick = (direction: string) => {
		if (direction === 'next' || direction === 'skip-forth-step') {
			const formSummaryFromLocalStorage = localStorage.getItem('forms');
			const forms = formSummaryFromLocalStorage ? JSON.parse(formSummaryFromLocalStorage) : {};
			const formElements = document.querySelectorAll('form');

			let isAllFormsValid = true;
			let firstInvalidElement: any;

			formElements.forEach((form) => {
				const formElements = form.elements;

				Array.from(formElements).forEach((element) => {
					if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement || element instanceof HTMLSelectElement) {
						if (!element.checkValidity()) {
							isAllFormsValid = false;
							if (!firstInvalidElement) {
								firstInvalidElement = element;
							}
							if (currentStep === 1 && element.type === 'file') {
								const ownerIndex = form.getAttribute('name');
								const fileName = element.id;
								const parentElement: any = element.closest('.file-wrapper');

								if (parentElement) {
									parentElement.style.position = 'relative';
									parentElement.focus();
								}

								if (ownerIndex !== null) {
									const numericOwnerIndex = Number(ownerIndex);
									if (!isNaN(numericOwnerIndex) && numericOwnerIndex >= 0 && numericOwnerIndex < owners.length) {
										const owner = owners[numericOwnerIndex];
										if (owner && owner.files && owner.files[fileName] && owner.files[fileName].file) {
											isAllFormsValid = true;
											firstInvalidElement = null;
										}
									}
								}
							}
						}
					}
				});
			});

			if (currentStep === 3 && forms.businessDetails.agra2_flag === '1') {
				const extraDetailsBoolFromLocalStorage = getFormDataInLocalStorage('extraDetailsFormValid');
				if (!extraDetailsBoolFromLocalStorage) return;
			}

			if (firstInvalidElement) {
				if (currentStep === 4 || currentStep === 1) {
					const labelElement = document.getElementById(`${firstInvalidElement.name}_title`);
					if (labelElement) {
						labelElement.focus();
						if (document.activeElement === labelElement) {
							setValidityChanged(validityChanged + 1);
							return;
						}
					}
				}

				firstInvalidElement.focus();
				setValidityChanged(validityChanged + 1);
				return;
			}

			if (!isAllFormsValid) return;
		}

		let stepAction = direction;
		if (direction === 'next' && currentStep === 0) {
			if (!chosenProfessions || !chosenProfessions?.length) return;
			if (!isRequestCabahutPrivileged) {
				stepAction = mifratLogic ? 'next' : 'skip-forth-step';
			}
		} else if (direction === 'prev' && currentStep === 5) {
			if (isRequestCabahutPrivileged || mifratLogic) {
				stepAction = 'prev';
			} else {
				stepAction = 'prev-skip-forth-step';
			}
		}
		setValidityChanged(0);
		updateStep(stepAction);
	};

	return (
		<div className="nav-arrows flex center">
			<button
				type="button"
				className={`nav-arrows__arrow nav-arrows__arrow_prev flex center ${currentStep > 0 ? 'shown' : 'invisible'} `}
				onClick={() => handleStepClick('prev')}
				disabled={currentStep === 0}
			>
				<span className="button-text">הקודם</span> <MdOutlineNavigateNext />
			</button>
			{currentStep !== 5 ? (
				<button type="button" className={`nav-arrows__arrow nav-arrows__arrow_next flex center`} onClick={() => handleStepClick('next')}>
					<MdOutlineNavigateBefore />
					<span className="button-text">הבא</span>
				</button>
			) : (
				<button className={`nav-arrows-send nav-arrows-send_active `} onClick={sendForm} type="button">
					{isLoading ? 'שולח...' : 'שלח'}
				</button>
			)}
		</div>
	);
}
