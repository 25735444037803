import { useEffect, useState } from 'react';
import mainService from '../../api/MainService';
import { Event } from '../../ts/types';
import { getFormDataInLocalStorage, setFormDataInLocalStorage } from '../../utils/localStorage';
import { FrontendPhrase } from '../../ts/interfaces';

export const useChosenProfessions = () => {
	const [queryValue, setQueryValue] = useState('');
	const [allProfessions, setAllProfessions] = useState<any[]>([]);
	const [professionsByQuery, setProfessionsByQuery] = useState<any[]>([]);
	const [phrases, setPhrases] = useState<any[]>([]);
	const [isFreeText, setIsFreeText] = useState(false);
	const [surfaceInputToDisplay, setSurfaceInputToDisplay] = useState<'agra3' | 'surface' | 'both'>('both');
	const [link, setLink] = useState('');
	const [groups, setGroups] = useState<string[]>([]);
	const [professions, setProfessions] = useState<any[]>([]);
	const [chosenProfessions, setChosenProfessions] = useState<any[]>(localStorage.getItem('forms') as unknown as any[]);
	const [currentGroup, setCurrentGroup] = useState({
		text: '',
		id: '',
	});
	const [isRequestCabahutPrivileged, setIsRequestCabahutPrivileged] = useState<boolean>(true);
	const [advancedNegishutRequirements, setAdvancedNegishutRequirements] = useState<boolean | null>(null);
	const [mifratLogic, setMifratLogic] = useState<boolean>(false);

	useEffect(() => {
		const res = getFormDataInLocalStorage('forms', []);
		setChosenProfessions(res.chosenProfessions);
	}, []);

	const filterArrayByChosenProfessions = (array: any[]) => {
		const newArr = array?.map((item: any) => {
			const isChecked = chosenProfessions?.some((prof: { id: string }) => prof.id === item.id);
			return { ...item, isChecked };
		});
		return newArr;
	};

	useEffect(() => {
		const fetchPhrases = async () => {
			try {
				if (!chosenProfessions.length) return;
				setPhrases([]);
				const pharseByChosenProfessions = await mainService.getProfessionPhrases(chosenProfessions?.map((prof) => prof.profession?.trim()));

				setPhrases(pharseByChosenProfessions);
			} catch (error) {}
		};

		fetchPhrases();
	}, [chosenProfessions]);

	const addToChosenProfessions = (item: any) => {
		setChosenProfessions((prev) => (Array.isArray(prev) ? [...prev, item] : [item]));
		setFormDataInLocalStorage(chosenProfessions, 'chosenProfessions');
	};

	const getProfessionsByGroupId = async (id: string) => {
		try {
			const res = allProfessions.filter((profession) => profession.groupe === id);
			const newArr = filterArrayByChosenProfessions(res);
			setProfessions(newArr);
		} catch (err) {
			console.log(err);
		}
	};

	const filterProfessionsByGroupId = () => {
		const newArr = filterArrayByChosenProfessions(professions);
		setProfessions(newArr);
	};

	const removeFromChosenProfessions = (itemToRemove: any) => {
		setChosenProfessions(() => chosenProfessions.filter((item: { id: string }) => item.id !== itemToRemove.id));
	};

	const getGroups = async () => {
		try {
			const res = await mainService.getStaticData('groups');
			if (res) {
				setGroups(res);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const updateCurrentGroupText = (event: Event & { target: HTMLButtonElement }) => {
		setCurrentGroup((prev) => ({
			...prev,
			text: event.target.value,
		}));
	};

	const updateCurrentGroupId = (id: any) => {
		setCurrentGroup((prev) => ({
			...prev,
			id,
		}));
	};

	const createLink = (profession: any) => {
		const lettersProfession = profession.match(/[\u0590-\u05FF\uFB2A-\uFB4E]/g) || ''; // Filter hebrew letters
		const decimalProfession = profession.match(/(-\d+|\d+)(,\d+)*(\.\d+)*/g);
		const item = decimalProfession
			.join('')
			.split('')
			.filter((char: any) => char !== '0' && char !== '.')
			.join('.');

		const group = item[0];

		setLink(`https://www.gov.il/he/departments/dynamiccollectors/add-zav-rishuy?skip=0&group_num=0${group}&name_item=${item}%20${lettersProfession}`);
	};

	const updateFreeTextState = () => {
		setIsFreeText(!isFreeText);
		if (!isFreeText) {
			setQueryValue('');
			setProfessions([]);
			setCurrentGroup({
				text: '',
				id: '',
			});
		} else {
			setProfessionsByQuery([]);
		}
	};

	const getAllProfessions = async () => {
		try {
			const res = await mainService.getStaticData('professions');

			if (res.length) {
				const newArr = filterArrayByChosenProfessions(res);
				setAllProfessions(newArr);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleQuery = (event: Event) => {
		const { value } = event.currentTarget;
		setQueryValue(value);
	};

	const getProfessionsByQuery = (value: string) => {
		const hasLength = value.length > 0;
		const chosenByKeyWord = allProfessions.filter((prof: { descrip: string }) => {
			if (hasLength) {
				if (prof.descrip !== null) return prof.descrip.includes(value);
			}
		});
		const newArr = filterArrayByChosenProfessions(chosenByKeyWord);
		setProfessionsByQuery(newArr);
	};

	const checkMaslulCabahutNew = (builtSurface: number, totalSurface: number) => {
		const privilege = { cabahut_ptor: 0, cabahut_maslul: 0 };
		if (!chosenProfessions) return privilege;
		const allProfessionsHavePtor = chosenProfessions?.every((profession) => {
			return profession.cabahut_ptor === 1 || profession.cabahut_ptor === '1';
		});

		const allProfessionsExceedBuiltSurface = chosenProfessions?.every((profession) => {
			return profession.cabahut_ptor_surface > builtSurface;
		});

		const allProfessionsHaveTazhir = chosenProfessions?.every((profession) => {
			return profession.cabahut_maslul === 1 || profession.cabahut_maslul === '1';
		});

		const allProfessionsMatchTazhirCondition = chosenProfessions?.every((profession) => {
			switch (profession.cabahut_surface_all_or_mvune) {
				case 0:
					setSurfaceInputToDisplay('surface');
					return profession.cabahut_surface > builtSurface; // האם השטח המבונה קטן מהשטח המבונה בפריט
				case 1:
					setSurfaceInputToDisplay('agra3');
					return profession.cabahut_colel_surface > totalSurface; // האם השטח הכולל קטן מהשטח הכולל בפריט
				case 2:
					setSurfaceInputToDisplay('both');
					return profession.cabahut_colel_surface > totalSurface && profession.cabahut_surface > builtSurface; // גם השטח המבונה וגם השטח הכולל קטן מהשטח בפריט
				default:
					return false;
			}
		});

		if (allProfessionsHavePtor || allProfessionsExceedBuiltSurface) {
			privilege.cabahut_ptor = 1;
		}

		if (allProfessionsHaveTazhir || allProfessionsMatchTazhirCondition) {
			privilege.cabahut_maslul = 1;
		}
		setIsRequestCabahutPrivileged(privilege.cabahut_ptor === 1 || privilege.cabahut_maslul === 1);
		return privilege;
	};

	const getCabahutFinalResult = (): boolean => {
		if (!JSON.parse(localStorage.getItem('forms') ?? '')) return false;
		let is_getting_cabahut_priv = true;
		const formSummaryFromLocalStorage = localStorage.getItem('forms');
		const forms = formSummaryFromLocalStorage ? JSON.parse(formSummaryFromLocalStorage) : {};
		let all_phrases_are_true = false;
		if (forms.formSummary.chosenPhrases)
			all_phrases_are_true = Object.keys(forms.formSummary.chosenPhrases)
				.filter((phrase: any) => phrase.licensing_factor_code === 6)
				.every((_, value) => value === 1);
		const placed_is_ok = forms.formSummary.where_is_placed === '3'; // אם התשובה היא אף אחת מהאפשרויות למיקום העסק
		const multy_building_flag = forms.formSummary.multy_building_flag === '1'; // מבנה רב שימוש
		const is_entrance = forms.formSummary.idsend === 1; // בודק מיקום העסק במבנה

		if (multy_building_flag && (!is_entrance || forms.chosenProfessions?.some((prof: { profession: string }) => prof.profession === '6.4' || forms.businessDetails?.surface > 300))) {
			is_getting_cabahut_priv = false;
		}

		if (!all_phrases_are_true || !placed_is_ok) {
			is_getting_cabahut_priv = false;
		}
		return is_getting_cabahut_priv;
	};

	const checkTazhirNegishut = () => {
		const chosenPhrases: FrontendPhrase[] = Object.values(JSON.parse(localStorage.getItem('forms') as string)?.formSummary?.chosenPhrases || {}) || [];
		const allNegishutPhrases = chosenPhrases?.filter((pf) => {
			return pf.licensing_factor_code === -1;
		});
		const allNegishutPhrasesAreValid = allNegishutPhrases?.every((pf) => pf.is_true === 1);
		const thereIsNegishut = chosenProfessions?.some((prof) => prof.nagish === 1) && allNegishutPhrasesAreValid;
		return { thereIsNegishut };
	};

	const checkIdMaslulim_34 = () => {
		setMifratLogic(chosenProfessions?.some((profession: any) => profession.mifrat_checked_flag === 1 && (profession.idmaslulim_34_1 === 3 || profession.idmaslulim_34_2 === 3)));
	};

	function hasNonSixLicensingFactorCode() {
		return !phrases?.some((phrase: any) => phrase.licensing_factor_code !== 6);
	}

	function defineNegishutRequirements(phrasesArray: any): void {
		for (const phrase of phrasesArray) {
			const value = parseInt(phrase.value);
			const numberToCompare = parseInt(phrase.number_to_compare);
			if (value < numberToCompare) {
				setAdvancedNegishutRequirements(false);
			} else {
				setAdvancedNegishutRequirements(true);
				break;
			}
		}
	}

	return {
		phrases,
		getCabahutFinalResult,
		isRequestCabahutPrivileged,
		setIsRequestCabahutPrivileged,
		checkMaslulCabahutNew,
		checkTazhirNegishut,
		filterProfessionsByGroupId,
		getProfessionsByQuery,
		getAllProfessions,
		link,
		hasNonSixLicensingFactorCode,
		checkIdMaslulim_34,
		handleQuery,
		mifratLogic,
		queryValue,
		surfaceInputToDisplay,
		groups,
		isFreeText,
		updateFreeTextState,
		getGroups,
		allProfessions,
		professions,
		currentGroup,
		chosenProfessions,
		createLink,
		professionsByQuery,
		updateCurrentGroupId,
		updateCurrentGroupText,
		addToChosenProfessions,
		getProfessionsByGroupId,
		removeFromChosenProfessions,
		advancedNegishutRequirements,
		setAdvancedNegishutRequirements,
		defineNegishutRequirements,
	};
};
