import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './component.style.scss';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useStore } from '../../store';
// import InputLabel from "@mui/material/InputLabel";

const theme = createTheme({
	direction: 'rtl',
});

type AutocompleteProps = {
	options: string[];
	name: string;
	placeholder?: string;
	errorMessage: string;
	value: string;
	handleChange: any;
	handleClickProp: any;
	legend: string;
	formNumber?: number;
	isRequired?: boolean;
	id: string;
};

export default function MuiAutocomplete({ id, placeholder, options, name, errorMessage, value, handleChange, handleClickProp, legend, formNumber, isRequired = false }: AutocompleteProps) {
	const { setBusinessDetailsValues, businessDetailsValues, setIsBusinessDetailsFormValid } = useStore().businessDetailsFormContext;
	const { setOwners } = useStore().ownersFormContainerContext;

	return (
		<fieldset className={`fieldset`} style={{ border: 'none', padding: 0, direction: 'rtl' }} title={legend} aria-label={legend}>
			<label className="autocomplete-legend" style={{ display: 'block', width: 'auto' }} title={legend} aria-label={legend}>
				<span className="required" title={legend} aria-label={legend}>
					{isRequired && '*'}
				</span>
				{legend}:
			</label>
			<Autocomplete
				id={id}
				key={id}
				aria-placeholder={legend}
				aria-labelledby={legend}
				// role="menu"
				freeSolo
				title={legend}
				aria-details={legend}
				aria-label={legend}
				disableClearable
				options={options}
				getOptionLabel={(option) => option}
				renderOption={(props, option) => (
					<li {...props} key={`${option}-${props.id}`}>
						{option}
					</li>
				)}
				value={value}
				inputValue={value}
				onChange={(event, newValue: any) => {
					if (typeof newValue === 'string') {
						handleClickProp(newValue, name);
					} else if (newValue && newValue.inputValue) {
						handleClickProp(newValue, name);
					} else {
						handleClickProp(newValue, name);
					}
				}}
				onInputChange={(event, newInputValue) => {
					if (event.type === 'click') {
						handleClickProp(newInputValue, name);
						return;
					}
					if (formNumber === 1)
						setBusinessDetailsValues({
							...businessDetailsValues,
							[name]: newInputValue,
						});
					const isValidValue = options.includes(newInputValue);
					if (!isValidValue && formNumber === 1) {
						setIsBusinessDetailsFormValid(false);
						return;
					}
					if (!isValidValue && formNumber === 2) {
						handleChange(event, newInputValue);
						setOwners((prevOwners: any) =>
							prevOwners.map((owner: any) => {
								return { ...owner, [name]: newInputValue, isValid: false };
							})
						);
						return;
					}
					handleChange(event, newInputValue);
				}}
				sx={{
					'.MuiFormControl-root': {
						textAlign: 'right',
						direction: 'rtl',
						margin: '0',
						position: 'relative',
					},
					'.MuiInputBase-input': {
						textAlign: 'right',
						direction: 'rtl',
						margin: 0,
					},
					'.MuiFormHelperText-root': {
						textAlign: 'right',
						direction: 'rtl',
						margin: '0',
					},
					'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
						border: '1px solid #eee',
					},
				}}
				renderInput={(params) => {
					let helperText = '';
					if (errorMessage) {
						helperText = errorMessage;
					} else if (isRequired && value?.trim() === '') {
						helperText = 'זהו שדה חובה';
					}
					return (
						<ThemeProvider theme={theme}>
							<TextField
								{...params}
								key={id}
								placeholder={placeholder ?? legend}
								required={isRequired && !value}
								dir="rtl"
								role="menuitem"
								margin="normal"
								error={!!errorMessage}
								helperText={helperText}
								FormHelperTextProps={{
									'aria-label': `${errorMessage} ${legend}`,
								}}
								InputProps={{
									...params.InputProps,
									type: 'search',
								}}
								name={name}
								// label={<InputLabel htmlFor={params.id}>{legend}</InputLabel>}
								sx={{
									'.MuiInputBase-input': {
										textAlign: 'right !important',
										direction: 'rtl',
										margin: 0,
									},
									'.MuiInputBase-root': {
										direction: 'rtl !important',
										margin: '0 !important',
										textAlign: 'right !important',
										height: '38px !important',
										border: '1px solid #484848',
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: 'transparent !important',
											borderWidth: '1px !important',
										},
										'&.Mui-error .MuiOutlinedInput-notchedOutline': {
											borderColor: '#f44336 !important',
											borderWidth: '1px !important',
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: '#484848 !important',
										},
									},
									'& .MuiFormHelperText-root': {
										display: 'block',
										position: 'absolute',
										bottom: '-20px',
										right: 0,
										textAlign: 'right',
									},
								}}
							/>
						</ThemeProvider>
					);
				}}
			/>
		</fieldset>
	);
}
