import { govLinks, streets } from './../db/govLinks';
import { Api } from './Api';
class GovService extends Api {
	getVillages() {
		return this.axiosRequest(govLinks.villagesArray, { headers: undefined });
	}

	getGlobalStreetsByChosenVillage(ident: string) {
		return this.axiosRequest(streets(ident), {
			headers: undefined,
		});
	}

	getStreetByCurrentVillage(code: string) {
		return this.axiosRequest(streets(code), {
			headers: undefined,
		});
	}
}

export const govService = new GovService();
