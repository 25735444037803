import { useState } from 'react';
import { setFormDataInLocalStorage } from '../../utils/localStorage';
import { FrontendPhrase, PhraseFromDb } from '../../ts/interfaces';

const commentsMapping: { [key: string]: string } = {
	startDate: 'date_send_first',
	endDate: 'date_nitra_first',
	size: 'agra_3',
	numberOfPeople: 'seats',
	numberOfRooms: 'rooms',
	numberOfFloors: 'number_of_floors',
	hazardousMaterials: 'hazard_materials_flag',
	openArea: 'open_space_flag',
	numberOfBeds: 'number_of_beds',
	height: 'ceilling_height',
	ammunitionProduction: 'weapon_flag',
	sauna: 'spa_flag',
	buildedAreaSize: 'agra3',
	totalAreaSize: 'surface',
	underSkyAreaSize: 'surface',
	storageHeight: 'storage_height',
	internalGasStation: ' ',
	oilType: 'sug_neft',
	oilVolume: 'kibolet',
	oneTimeEvent: 'main',
	firearmsAndAmmunition: 'number_of_shotgun',
	eventType: 'profession_descripl',
	licensingTypeCode: 'licensingTypeCode',
	licensingType: 'licensingType',
};

export const useProfessionPhraseForm = () => {
	const [professionPhraseFormValues, setProfessionPhraseFormValues] = useState<any>({
		mezuraz_bniya_1_flag: null,
		mezuraz_bniya_2_flag: null,
		mezuraz_bniya_3_flag: null,
		where_is_placed: null, // היכן ממוקם העסק
		cabahut_maslul: null,
		cabahut_ptor: null,
		idsend: null,
		multy_building_flag: null,
		professionPhraseFormValues: null,
		chosenPhrases: {},
	});
	const [isProfessionPhraseFormValid, setIsProfessionPhraseFormValid] = useState(false);

	const [displayValues, setDisplayValues] = useState<FrontendPhrase[]>([]);

	const updateFormsContainer = (formToUpdate: string, formValues: any) => {
		let updatedForms = JSON.parse(localStorage.getItem('forms') || '{}');
		updatedForms = {
			...updatedForms,
			[formToUpdate]: formValues,
		};
		setFormDataInLocalStorage(updatedForms, 'forms');
	};

	const handleCheckboxChange = (phrase: PhraseFromDb, isChecked: boolean, isInChosenProfessions: boolean) => {
		const { id, dossier_column_name, descrip, component_type, profession, number_to_compare, gov_il_code, licensing_factor_code } = phrase;
		const updatedChosenPhrases: any = { ...professionPhraseFormValues.chosenPhrases };
		if (!isInChosenProfessions) {
			delete updatedChosenPhrases[id];
		} else {
			updatedChosenPhrases[id] = {
				id,
				dossier_column_name,
				descrip,
				component_type,
				profession,
				number_to_compare,
				gov_il_code,
				licensing_factor_code,
				value: isChecked ? 1 : 0,
				is_true: isChecked ? 1 : 0,
			};
		}
		setProfessionPhraseFormValues((prevState: any) => ({
			...prevState,
			chosenPhrases: updatedChosenPhrases,
		}));

		checkAndSetIsFormValid();
	};

	const handleInputChange = (phrase: FrontendPhrase, id: number, value: string) => {
		const updatedDisplayValues = [...displayValues];
		const index = updatedDisplayValues.findIndex((item) => item.id === id);
		let is_true = +value < phrase.number_to_compare;

		if (index !== -1) {
			updatedDisplayValues[index].value = value;
		} else if (phrase) {
			updatedDisplayValues.push({
				id: phrase.id,
				value,
				dossier_column_name: phrase.dossier_column_name,
				component_type: phrase.component_type,
				descrip: phrase.descrip,
				profession: phrase.profession,
				gov_il_code: phrase.gov_il_code,
				licensing_factor_code: phrase.licensing_factor_code,
				number_to_compare: phrase.number_to_compare,
				is_index: phrase.is_index,
				is_true: is_true ? 1 : 0,
				radio_button_behavior: phrase.radio_button_behavior,
			});
		}

		if (phrase.component_type?.trim() === 'number') {
			const updatedChosenPhrases: any = { ...professionPhraseFormValues.chosenPhrases };
			updatedChosenPhrases[id] = {
				id,
				dossier_column_name: phrase.dossier_column_name,
				descrip: phrase.descrip,
				component_type: phrase.component_type,
				profession: phrase.profession,
				number_to_compare: phrase.number_to_compare,
				gov_il_code: phrase.gov_il_code,
				is_true: is_true ? 1 : 0,
				licensing_factor_code: phrase.licensing_factor_code,
				value,
			};
			setProfessionPhraseFormValues((prevState: any) => ({
				...prevState,
				chosenPhrases: updatedChosenPhrases,
			}));
		}
		setDisplayValues(updatedDisplayValues);
		checkAndSetIsFormValid();
	};

	const checkAndSetIsFormValid = () => {
		const form = document.querySelector('.form') as HTMLFormElement;
		const isFormValid = form.checkValidity();
		setIsProfessionPhraseFormValid(isFormValid);
	};
	const handleChange = (event: any) => {
		const target = event.target;
		const name = target.name;
		const value = target.value;

		if (target.type === 'checkbox') {
			setProfessionPhraseFormValues((prevState: { [x: string]: string }) => ({
				...prevState,
				[name]: prevState[name as keyof typeof prevState] !== '1' ? '1' : '0',
			}));
		} else {
			setProfessionPhraseFormValues({
				...professionPhraseFormValues,
				...{ [name]: value },
			});
		}

		checkAndSetIsFormValid();
	};

	const clearMifratValues = () => {
		setProfessionPhraseFormValues((prevState: any) => ({
			...prevState,
			mezuraz_bniya_1_flag: '-1',
			mezuraz_bniya_3_flag: '-1',
		}));
	};

	function deduplicateArrayByKey<T extends Record<K, string>, K extends keyof T>(array: T[], key: K): T[] {
		const seen = new Set<string>();
		const result: T[] = [];

		for (const item of array) {
			const uniqueKey = item[key]?.trim();
			if (uniqueKey === '') {
				result.push(item);
				continue;
			}

			if (uniqueKey !== undefined) {
				if (!seen.has(uniqueKey)) {
					seen.add(uniqueKey);
					result.push(item);
				}
			} else {
				result.push(item);
			}
		}
		return result;
	}

	function mapValues(phrases: PhraseFromDb[]) {
		const result: { component_type: string; value: string; id: number; descrip: string; profession: string; gov_il_code: number }[] = [];
		const currentFormsValues = localStorage.getItem('forms');
		const forms = currentFormsValues ? JSON.parse(currentFormsValues) : {};
		const currentBusinessDetails = forms.businessDetails || {};
		for (const phrase of phrases) {
			const {
				id,
				dossier_column_name,
				descrip,
				component_type,
				profession,
				number_to_compare,
				gov_il_code,
				is_index,
				dossier_column_name_pointer,
				descrip_addition,
				licensing_factor_code,
				radio_button_behavior,
				question_should_be_displayed,
			} = phrase;
			const trimmedColumnName = dossier_column_name?.trim();
			const trimmedDescrip = descrip?.trim();
			const trimmedDescripAddition = descrip_addition?.trim() ?? '';
			const trimmedProfession = profession?.trim();
			const trimmedRadioButtonBehavior = radio_button_behavior?.trim();
			const trimmedComponentType = component_type?.trim();
			const trimmedQuestionShouldBeDisplayed = question_should_be_displayed?.trim();
			const trimmedDossierColumnNamePointer = dossier_column_name_pointer?.trim();
			const mappedKey = Object.keys(commentsMapping).find((key) => commentsMapping[key] === trimmedColumnName);
			if (mappedKey) {
				const resultObj = {
					component_type: trimmedComponentType,
					value: currentBusinessDetails[commentsMapping[mappedKey]] || '',
					id: id,
					is_index,
					profession: trimmedProfession,
					descrip: trimmedDescrip,
					descrip_addition: trimmedDescripAddition,
					dossier_column_name: trimmedColumnName,
					gov_il_code,
					licensing_factor_code,
					dossier_column_name_pointer: trimmedDossierColumnNamePointer ?? '',
					radio_button_behavior: trimmedRadioButtonBehavior,
					question_should_be_displayed: trimmedQuestionShouldBeDisplayed,
				};
				result.push(resultObj);
			} else {
				const resultObj = {
					component_type: trimmedComponentType,
					value: '',
					id: id,
					is_index,
					profession: trimmedProfession,
					descrip: trimmedDescrip,
					descrip_addition: trimmedDescripAddition,
					dossier_column_name: trimmedColumnName,
					dossier_column_name_pointer: trimmedDossierColumnNamePointer ?? '',
					number_to_compare,
					gov_il_code,
					licensing_factor_code,
					radio_button_behavior: trimmedRadioButtonBehavior,
					question_should_be_displayed: trimmedQuestionShouldBeDisplayed,
				};
				result.push(resultObj);
			}
		}

		return result;
	}

	const removePhrasesValues = (itemToRemove: any) => {
		const updatedChosenPhrases: any = { ...professionPhraseFormValues.chosenPhrases };
		Object.entries(updatedChosenPhrases).forEach(([key, value]: any) => {
			if (value.profession.trim() === itemToRemove.profession.trim()) {
				delete updatedChosenPhrases[key];
			}
		});
		setProfessionPhraseFormValues((prevState: any) => ({
			...prevState,
			chosenPhrases: updatedChosenPhrases,
		}));
	};

	return {
		clearMifratValues,
		updateFormsContainer,
		setProfessionPhraseFormValues,
		checkAndSetIsFormValid,
		displayValues,
		handleInputChange,
		setDisplayValues,
		handleCheckboxChange,
		professionPhraseFormValues,
		isProfessionPhraseFormValid,
		handleChange,
		mapValues,
		deduplicateArrayByKey,
		removePhrasesValues,
	};
};
