import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { DecryptionService } from './decryptionService';

export abstract class Api {
	private decryptionService: DecryptionService;

	constructor() {
		this.decryptionService = new DecryptionService();
	}

	protected async axiosRequest(url: string, config: AxiosRequestConfig) {
		try {
			const res: AxiosResponse = await axios.request({ url, ...config });
			// return res.data
			// // Check if the response data is encrypted
			if (res.data && typeof res.data.data === 'string') {
				try {
					const decryptedData = this.decryptionService.decryptDataFully(res.data.data);
					return decryptedData;
				} catch (decryptionError) {
					console.error('Decryption error:', decryptionError);
					throw new Error('Failed to decrypt response data');
				}
			}

			// If the data is not encrypted, return it as is
			return res.data;
		} catch (error: any) {
			if (axios.isAxiosError(error) && error.response) {
				// If it's an Axios error with a response, return the response data
				return Promise.reject(`Error: ${error.response.status} - ${error.response.data}`);
			}
			// For other types of errors, return a generic error message
			return Promise.reject(`Error: ${error.message || 'Unknown error occurred'}`);
		}
	}
}
